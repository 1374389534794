import { Document } from "@contentful/rich-text-types"
import { graphql, PageProps } from "gatsby"
import { FluidObject } from "gatsby-image"
import React, { Fragment, FunctionComponent, useState } from "react"
import { ValueType } from "react-select"
import {
  Filter,
  isSingleValidValue,
  SimpleOption,
} from "../../components/common/filters"
import SEO from "../../components/common/seo"
import Ticker from "../../components/common/ticker"
import { dropdownStyle } from "../../components/explore/dropdown.style"
import HeroTitleSection from "../../components/hero-title/heroTitleSection"
import Layout from "../../components/layout/layout"
import renderMarkdown from "../../services/markdownRenderer"
import { ApiRetailer, sortRetailers } from "../../services/retailersService"
import { COLORS } from "../../styles"
import Style from "./category.style"
import RetailerCard from "./RetailerCard"
import CarouselElement from "./RetailerCarousel"

export const VARIANTS = {
  NO_NEWS: "noNews",
  NO_EVENT: "noEvent",
}

interface Data {
  contentfulCategory: {
    name: string
    image: {
      title: string
      fluid: FluidObject
    }
    metaDescription?: string
    description?: {
      description?: string
    }
    descriptionRichText?: {
      json: Document
    }
    retailerTitle: string
    shouldDisplayTableFilters: boolean
    subcategories: {
      name: string
      slug: string
    }[]
    carouselTitle: string
    carouselElements?: any
  }
  contentfulEventsPage: {
    slug: string
  }
  allContentfulRetailer: {
    edges: {
      node: ApiRetailer
    }[]
  }
}

const Category: FunctionComponent<PageProps<Data>> = ({ data }) => {
  const categoryRetailers = data.allContentfulRetailer.edges
    .map(edge => edge.node)
    // do not simplify
    .filter(item => item.display !== false)
  const {
    name,
    image,
    retailerTitle,
    metaDescription,
    keywords,
    description,
    descriptionRichText,
    subcategories,
    carouselTitle,
    carouselElements,
  } = data.contentfulCategory

  const pageSlug = {
    Itinerary: "itineraries", // Depends on the name of file in Pages folder
    Events: data.contentfulEventsPage.slug,
  }

  const filterSubcategories = [{ value: "all", label: "All" }].concat(
    subcategories &&
      subcategories.map((subcategory: any) => ({
        value: subcategory.slug,
        label: subcategory.name,
      }))
  )

  const retailers =
    categoryRetailers &&
    categoryRetailers
      .sort(sortRetailers)
      .filter(retailer => retailer.display !== false)

  const [filterSubcategory, setFilterSubcategory] = useState<{
    value: string | null
    label: string
  }>({
    value: "all",
    label: "All",
  })

  const [subcategoryRetailers, setSubcategoryRetailers] = useState(retailers)

  const [filteredRetailers, setFiltredRetailers] = useState(
    subcategoryRetailers && subcategoryRetailers.slice(0, 16)
  )

  const handleFilter = (value: ValueType<SimpleOption>) => {
    if (isSingleValidValue(value)) {
      setFilterSubcategory(value)
      setSubcategoryRetailers(
        retailers &&
          retailers.filter(retailer =>
            value.value === "all"
              ? true
              : retailer.subcategory &&
                retailer.subcategory.find(
                  subcategory => subcategory.name === value.label
                )
          )
      )
      setFiltredRetailers(
        retailers &&
          retailers
            .filter(retailer =>
              value.value === "all"
                ? true
                : retailer.subcategory &&
                  retailer.subcategory.find(
                    subcategory => subcategory.name === value.label
                  )
            )
            .slice(0, 16)
      )
    }
  }

  return (
    <Layout>
      <SEO
        title={name}
        description={metaDescription}
        keywords={keywords || ""}
      />
      <HeroTitleSection name={name} image={image} />
      {descriptionRichText && (
        <Style.Description>
          {renderMarkdown(descriptionRichText.json)}
        </Style.Description>
      )}
      <Style.RetailersContainer>
        {subcategories && (
          <Filter
            currentValue={filterSubcategory.value}
            title={retailerTitle}
            backgroundColor={`${COLORS.CHALK_VIOLET}`}
            options={filterSubcategories}
            placeholder={"All ".concat(name)}
            styles={dropdownStyle}
            onChange={handleFilter}
          />
        )}
        {filteredRetailers && (
          <Style.Retailers.Container>
            {filteredRetailers.map((retailer, index) => (
              <Fragment key={retailer.id}>
                <RetailerCard
                  name={retailer.name}
                  heroImage={retailer.heroImage}
                  subcategory={retailer.subcategory}
                  slug={retailer.slug}
                  address={retailer.address}
                />
                {index !== filteredRetailers.length - 1 ? (
                  <Style.Retailers.Line />
                ) : (
                  ""
                )}
              </Fragment>
            ))}
            {subcategoryRetailers.length > filteredRetailers.length && (
              <Style.Retailers.SeeMore
                icon="rightPlus"
                mode="button"
                onClick={() => {
                  setFiltredRetailers(subcategoryRetailers)
                }}
              >
                See More
              </Style.Retailers.SeeMore>
            )}
          </Style.Retailers.Container>
        )}
      </Style.RetailersContainer>
      {carouselElements && (
        <div>
          <Style.Ticker.Title $textColor={COLORS.HOLLY_GREEN}>
            {carouselTitle}
          </Style.Ticker.Title>
          <Style.Ticker.Container>
            <Ticker>
              {carouselElements.map((element: any) => {
                const type:
                  | "Events"
                  | "Itinerary" = element.__typename.substring(
                  10,
                  element.__typename.length
                )
                const slug =
                  element.slug &&
                  pageSlug[type].concat("/").concat(element.slug)
                return (
                  <CarouselElement
                    type={type}
                    title={element.title}
                    slug={slug}
                    image={element.image}
                    creator={element.creator}
                    endDate={element.endDate}
                    externalLink={element.externalLink}
                    key={element.slug}
                  />
                )
              })}
            </Ticker>
          </Style.Ticker.Container>
        </div>
      )}
    </Layout>
  )
}
export default Category

// TODO: Write schema definitions for GraphQL query so carouselElements is optional

export const query = graphql`
  query($categorySlug: String!) {
    contentfulCategory(slug: { eq: $categorySlug }) {
      name
      metaDescription
      keywords
      description {
        description
      }
      descriptionRichText {
        json
      }
      image {
        title
        fluid(quality: 90, maxWidth: 2000) {
          ...GatsbyContentfulFluid
        }
      }
      retailerTitle
      shouldDisplayTableFilters
      subcategories {
        name
        slug
      }
      carouselTitle
    }
    contentfulEventsPage {
      slug
    }
    allContentfulRetailer(
      filter: { category: { elemMatch: { slug: { eq: $categorySlug } } } }
    ) {
      edges {
        node {
          id
          name
          slug
          address
          phoneNumber
          lastColumnCategoryPageInformation
          display
          subcategory {
            name
          }
          heroImage {
            title
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
