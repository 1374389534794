import dayjs from "dayjs"
import { FluidObject } from "gatsby-image"
import React, { FunctionComponent } from "react"
import { COLORS } from "../../styles"
import Style from "./category.style"

interface Props {
  type: "Itinerary" | "Events" | "News"
  title: string
  slug: string
  image: {
    fluid: FluidObject
    title: string
  }
  creator: string
  endDate: string
  externalLink: string
}
const tagColor = {
  Itinerary: COLORS.LIGHT_BLUE_GREY,
  Events: COLORS.PINK,
  News: COLORS.BEIGE,
}

const CarouselElement: FunctionComponent<Props> = ({
  type,
  title,
  slug,
  image,
  creator,
  endDate,
  externalLink,
}) => {
  return (
    <Style.CarouselElement.Container>
      {image && (
        <Style.CarouselElement.Image fluid={image.fluid} alt={image.title} />
      )}
      <Style.CarouselElement.Container_1>
        <Style.CarouselElement.Tag color={tagColor[type]}>
          {type}
        </Style.CarouselElement.Tag>
        <Style.CarouselElement.Name>{title}</Style.CarouselElement.Name>
        <Style.CarouselElement.Subtitle>
          {(creator && "BY ".concat(creator)) ||
            (endDate &&
              "Through ".concat(dayjs(endDate).format("MMMM D, YYYY")))}
        </Style.CarouselElement.Subtitle>

        <Style.CarouselElement.CTA to={externalLink || `/${slug}`} id="ctaId">
          Learn More
        </Style.CarouselElement.CTA>
      </Style.CarouselElement.Container_1>
    </Style.CarouselElement.Container>
  )
}

export default CarouselElement
