/* eslint-disable react/display-name */
import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer"
import { BLOCKS, Document, INLINES } from "@contentful/rich-text-types"
import React, { ReactNode } from "react"
import styled from "styled-components"
import { H3, H4, H5, Q1, T1 } from "../components/common/text"
import { AutoLink } from "../components/link"
import { COLORS } from "../styles"

const Heading5 = styled(H5)`
  margin-bottom: 15px;
`
const Heading4 = styled(H4)`
  margin-bottom: 15px;
`
const Heading3 = styled(H3)`
  margin-bottom: 15px;
`
const Text = styled(T1)`
  line-height: 1.67;
`

const Paragraph = styled.div`
  margin-bottom: 30px;
`

const Link = styled(AutoLink)`
  text-decoration: underline;
  color: ${COLORS.BOTTLE_GREEN};
`

const Quote = styled(Q1)`
  max-width: 584px;
  margin: 0 auto;
  display: flex;
  &::before {
    content: "“";
    margin-right: 2px;
  }
  ${Paragraph} {
    &::after {
      content: "”";
      margin-left: 2px;
    }
  }
`

const options: Options = {
  renderNode: {
    [BLOCKS.QUOTE]: (_node, children) => <Quote>{children}</Quote>,
    [BLOCKS.DOCUMENT]: (_node, children) => (
      <Text $textColor={COLORS.CHARCOAL}>{children}</Text>
    ),
    [BLOCKS.PARAGRAPH]: (_node, children) => <Paragraph>{children}</Paragraph>,
    [BLOCKS.HEADING_3]: (_node, children) => <Heading3>{children}</Heading3>,
    [BLOCKS.HEADING_4]: (_node, children) => <Heading4>{children}</Heading4>,
    [BLOCKS.HEADING_5]: (_node, children) => <Heading5>{children}</Heading5>,
    [INLINES.HYPERLINK]: (_node, children) => (
      <Link to={_node.data.uri}>{children}</Link>
    ),
  },
}

const renderMarkdown = (document: Document): ReactNode =>
  documentToReactComponents(document, options)

export default renderMarkdown
