import { navigate } from "@reach/router"
import { FluidObject } from "gatsby-image"
import React, { FunctionComponent } from "react"
import { Media } from "../../Media"
import { COLORS } from "../../styles"
import Style from "./category.style"

interface Props {
  heroImage: {
    fluid: FluidObject
    title: string
  }
  subcategory: {
    name: string
  }[]
  name: string
  address: string
  slug: string
}
const RetailerCard: FunctionComponent<Props> = ({
  heroImage,
  subcategory,
  name,
  address,
  slug,
}) => (
  <Style.Retailers.Retailer.Container
    onClick={() => {
      navigate(`/${slug}`)
    }}
  >
    <Style.Retailers.Retailer.Image
      fluid={heroImage && heroImage.fluid}
      alt={heroImage.title}
    />
    <Style.Retailers.Retailer.Container_1>
      <Style.Retailers.Retailer.Subcategory>
        {subcategory && subcategory[0].name}
      </Style.Retailers.Retailer.Subcategory>
      <Style.Retailers.Retailer.Name>{name}</Style.Retailers.Retailer.Name>
      <Style.Retailers.Retailer.Address $textColor={COLORS.CHARCOAL}>
        {address}
      </Style.Retailers.Retailer.Address>
      <Media greaterThanOrEqual="md">
        <Style.Retailers.Retailer.CTA to={`/${slug}`}>
          More Info
        </Style.Retailers.Retailer.CTA>
      </Media>
    </Style.Retailers.Retailer.Container_1>
  </Style.Retailers.Retailer.Container>
)

export default RetailerCard
