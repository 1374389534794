import styled, { css } from "styled-components"
import {
  COLORS,
  IPAD_MAX_WIDTH,
  MOBILE_MAX_WIDTH,
  FONTS,
  IPHONE5_MAX_WIDTH,
  MD_BREAKPOINT,
  MAX_WIDTH_LAYOUT,
} from "../../styles"
import SectionTitle from "../../components/special-event/sectionTitle.style"
import { VARIANTS } from "./category"
import Img from "gatsby-image"
import Plus from "../../images/plus.svg"
import Button from "../../components/common/button"
import { CTA } from "../../components/common/cta"
import { S2, H5, T1, H4, S1 } from "../../components/common/text"

export default {
  RetailersTitle: styled(SectionTitle)`
    text-align: center;
    margin-bottom: 3rem;
  `,
  RetailersContainer: styled.div`
    background-color: ${COLORS.WHITE};
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      margin-bottom: 20px;
    }
  `,
  CardsContainer: styled.div`
    display: flex;
    justify-content: center;
    padding: 3rem 12rem;
    background-color: ${COLORS.AQUA_HAZE};
    @media screen and (max-width: ${IPAD_MAX_WIDTH}px) {
      padding: 3rem 8.33rem;
    }
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      padding: 0;
      flex-direction: column;
    }
  `,
  EventContainer: styled.div<{ variant: string }>`
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      padding: 3.67rem 2rem;
      background-color: ${COLORS.AQUA_HAZE};
    }

    ${props =>
      props.variant !== VARIANTS.NO_NEWS &&
      css`
        padding-right: 2rem;
        border-right: thin solid ${COLORS.BLACK};
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
          border-right: none;
        }
      `}
  `,
  CardHeader: styled(SectionTitle)`
    margin-bottom: 3rem;
  `,
  NewsContainer: styled.div<{ variant: string }>`
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      padding: 3.67rem 2rem;
      background-color: ${COLORS.WHITE};
    }

    ${props =>
      props.variant !== VARIANTS.NO_EVENT &&
      `
      padding-left: 2rem;
    `}
  `,

  Retailers: {
    Container: styled.div`
      display: grid;
      grid-template-columns: repeat(auto-fill, 370px);
      justify-content: center;
      //margin: 0 auto;
      padding: 50px;
      //max-width: ${MAX_WIDTH_LAYOUT}px;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        grid-template-columns: repeat(auto-fill, 100%);
        padding: 20px 30px;
      }
    `,
    Retailer: {
      Container: styled.div`
        display: flex;
        flex-direction: column;
        height: 350px;
        margin: 0px 12px 40px 12px;
        cursor: pointer;
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
          flex-direction: row;
          height: 92px;
          margin: 15px 0px;
        }
      `,
      Image: styled(Img)`
        width: 100%;
        object-fit: contain;
        margin-bottom: 15px;
        height: 200px;
        min-height: 200px;
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
          width: 134px;
          min-width: 134px;
          min-height: 92px;
          height: 92px;
          margin-right: 15px;
        }
        @media screen and (max-width: 700px) {
          width: 100px;
          min-width: 100px;
          min-height: 92px;
          height: 92px;
          margin-right: 15px;
        }
      `,
      Container_1: styled.div`
        position: relative;
        display: flex;
        flex-direction: column;
        height: 150px;
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
          height: unset;
          position: static;
        }
      `,
      Subcategory: styled(S1)`
        margin-bottom: 10px;
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
          margin-bottom: 5px;
          font-size: 11px;
          line-height: 1.27;
          letter-spacing: 1.32px;
        }
      `,
      Name: styled(H5)`
        margin-bottom: 15px;
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
          margin-bottom: 10px;
          font-size: 18px;
          line-height: 1.22;
          letter-spacing: 0.18px;
        }
        @media screen and (max-width: ${IPHONE5_MAX_WIDTH}px) {
          margin-bottom: 7px;
          font-size: 15px;
          line-height: 1.33;
          letter-spacing: 0.15px;
        }
      `,
      Address: styled(T1)`
        margin-bottom: 20px;
        @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
          font-size: 13px;
          line-height: 1.38;
          letter-spacing: 0.13px;
        }
      `,
      CTA: styled(CTA)`
        position: absolute;
        bottom: 0px;
      `,
    },
    Line: styled.hr`
      height: 0.8px;
      color: #d3d3d3d3;
      margin-bottom: 0px;
      width: 100%;
      @media screen and (min-width: ${MOBILE_MAX_WIDTH}px) {
        display: none;
      }
    `,
    SeeMore: styled(Button)`
      grid-column: 1 / -1;
      width: 100%;
    `,
    Plus: styled(Plus)`
      height: 9px;
      color: inherit;
    `,
  },
  CarouselElement: {
    Container: styled.div`
      display: flex;
      flex-direction: column;
      align-items: left;
      width: 580px;
      margin: 50px 46px 40px 0px;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        margin-top: 10px;
        width: 300px;
        margin-bottom: -5px;
      }
    `,
    Image: styled(Img)`
      width: 100%;
      object-fit: contain;
      margin-bottom: 15px;
      height: 380px;
      min-height: 380px;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        margin-bottom: 25px;
        width: 270px;
        height: 375px;
      }
    `,
    Container_1: styled.div`
      position: relative;
      display: flex;
      flex-direction: column;
      height: 200px;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        margin-left: 30px;
        width: 270px;
      }
    `,
    Tag: styled(S2)<{ color?: string }>`
      width: fit-content;
      height: 27px;
      padding: 8px 8px 6px 8px;
      text-align: center;
      background-color: ${props => props.color};
      margin-bottom: 15px;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        margin-bottom: 20px;
      }
    `,
    Name: styled(H4)`
      margin-bottom: 5px;
    `,
    Subtitle: styled(S2)`
      margin-bottom: 20px;
    `,
    CTA: styled(CTA)`
      position: absolute;
      bottom: 0;
    `,
  },
  Ticker: {
    Title: styled(S2)`
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        height: 80px;
      }
    `,
    Container: styled.div`
      position: relative;
      margin-left: -75px;
      height: 43rem;
      .flickity-page-dots {
        left: 30px;
      }
    `,
  },
  Description: styled.div`
    max-width: 860px;
    margin: 0 auto;
    padding: 40px 40px 0 40px;
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      padding: 30px 30px 0 30px;
    }
  `,
}
